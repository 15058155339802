import React from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
import CurrencyFormat from "react-currency-format";
import { BaseUrl } from "../common/api";
import "swiper/css/bundle";
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import RViewerJS from "viewerjs-react";
import "viewerjs-react/dist/index.css";
import { Link } from "react-router-dom";
import ProductCard from "../common/product-card";

import { actFetchProductsRequest, AddCart, DeleteCart } from "../Actions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import ReactHtmlParser from "html-react-parser";
import { PrimaryButton, SecondaryButton } from "../assets/static/js/Button";

// import image_1 from "../assets/static/img/image 12.jpg";
// import image_2 from "../assets/static/img/image 13.jpg";

const cookies = new Cookies();
class Product extends React.Component {
  constructor(props) {
    super(props);
    // Initialize state and class variables
    this.state = {
      message: {},
      takeMetrik: [],
      matrikDay: 0,
      takeLength: 0,
      metrikLength: 0,
      rectangle: [],
      oval: [],
      square: [],
      unusual: [],
      metrik: [],
      cart: [],
      show: false,
      text: "",
      formats: [],
      same_products: [],
      product: {
        collection: {},
      },
      delivery_dates: [],
    };

    this.sortedDay = [];
    this._isMounted = false;
    // detecting the language
    if (cookies.get("Language")) {
      this.lang = cookies.get("Language");
    } else {
      this.lang = "uz";
    }
    if (this.lang === "uz") {
      this.name = "nameUz";
      this.density = "densityUz";
      this.basis = "basisUz";
      this.pileHeight = "pileHeightUz";
      this.pileThread = "pileThreadUz";
      this.threadComposition = "threadCompositionUz";
      this.weight = "weightUz";
      this.edgeProcessing = "edgeProcessingUz";
      this.design = "designUz";
      this.description = "descriptionUz";
    } else {
      this.name = "nameRu";
      this.density = "densityRu";
      this.basis = "basisRu";
      this.pileHeight = "pileHeightRu";
      this.pileThread = "pileThreadRu";
      this.threadComposition = "threadCompositionRu";
      this.weight = "weightRu";
      this.edgeProcessing = "edgeProcessingRu";
      this.design = "designRu";
      this.description = "descriptionRu";
    }
    // detection the region for calculating delivery_days
    this.region = cookies.get("Region");
    this.showModal = this.showModal.bind(this);
    this.hideModal = this.hideModal.bind(this);
    this.decrement = this.decrement.bind(this);
    this.increment = this.increment.bind(this);
    this.deleteItem = this.deleteItem.bind(this);

    this.check = false;
    const now = new Date();
    // cookies expire date one week
    this.expires = new Date(now.getTime() + 7 * 24 * 60 * 60 * 1000);
  }

  async componentDidMount() {
    this.fetchingData();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.fetchingData();
    }
  }

  fetchingData = async () => {
    this._isMounted = true;
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
    const query = new URLSearchParams(this.props.location.search);
    const product_id = query.get("product_id");
    // fetching data
    this._isMounted &&
      (await axios
        .get(BaseUrl + `product-page/${product_id}/`)
        .then((res) => {
          const product = res.data.product;
          const same_products = res.data.same_products;
          let prod = JSON.parse(JSON.stringify(res.data.product));
          const metrik = prod.items.filter(
            (item) => item.size.type === 2 || item.size.type === 5
          );
          metrik.map((metr) => {
            const maxAndSum = metr.units.reduce(
              (acc, item) => {
                if (item.count > acc.max) {
                  acc.max = item.count;
                }
                acc.sum += item.count;
                return acc;
              },
              { max: -Infinity, sum: 0 }
            );
            let pro = product.items.find((item) => {
              return item.id === metr.id;
            });
            pro["fulllength"] = maxAndSum.sum;
            pro["maxlength"] = maxAndSum.max;
            return metr.fulllength;
          });
          const art = product.name.slice(2, 6);
          const nameUz = product.collection.nameUz;
          const nameRu = product.collection.nameUz;
          this._isMounted &&
            this.setState({
              same_products,
              product,
              art,
              nameUz,
              nameRu,
            });
          document.title =
            product.collection.nameUz + " # " + product.name.slice(2, 6);
        })
        .catch((err) => {
          console.log(err);
          console.log(err.message);
          console.log(err.response);
        }));

    this._isMounted &&
      // fetching delivery_dates by region
      (await axios
        .get(BaseUrl + "delivery-dates/?region=" + this.region)
        .then((res) => {
          const delivery_dates = res.data;
          this._isMounted &&
            this.setState({
              delivery_dates,
            });
        }));
    this.format = this.state.product.items;
    // generating units list holds only delivery_days
    this.format &&
      this.format.map((format) => {
        let units = [];
        if (format.isMakingAlways === true) {
          for (let i = 0; i < 20; i++) {
            let obj = {};
            obj["delivery_day"] =
              this.state.delivery_dates.find(
                (item) => parseInt(item.warehouse) === 1
              ).delivery_day + format.manufactureDay;
            obj["item"] = format.id;
            units.push(obj);
          }
        }
        format.units.map((el) => {
          try {
            el["delivery_day"] = this.state.delivery_dates.find(
              (item) => parseInt(item.warehouse) === parseInt(el.warehouse)
            ).delivery_day;
          } catch {
            el["delivery_day"] = 0;
          }

          return el.id;
        });
        format["units"] = [...units, ...format.units];
        return format.id;
      });
    this.others = [];
    this.format &&
      this.format.map((element) => {
        const days = [];
        element.units.map((unit) => {
          days.push(unit.delivery_day);
          return unit;
        });
        days.sort();
        element.units = days;
        return element.id;
      });

    this._isMounted &&
      this.setState({
        formats: this.format,
      });

    const rectangle = [];
    const oval = [];
    const square = [];
    const unusual = [];
    const metrik = [];
    // separating products by shapes
    this.state.formats.filter(
      (item) =>
        item.size.type === 1 &&
        item.units.length > 0 &&
        item.isActive === true &&
        rectangle.push(item)
    );

    this.state.formats.filter(
      (item) => item.size.type === "KV" && square.push(item)
    );
    this.state.formats.filter(
      (item) => item.size.type === "NO" && unusual.push(item)
    );

    this.state.formats.filter(
      (item) =>
        (item.size.type === 5 || item.size.type === 2) &&
        item.units.length > 0 &&
        item.isActive === true &&
        metrik.push(item)
    );

    this.state.formats.filter(
      (item) =>
        item.size.type === 0 &&
        item.units.length > 0 &&
        item.isActive === true &&
        oval.push(item)
    );

    this._isMounted &&
      this.setState({ rectangle, oval, square, unusual, metrik });

    if (this.state.product) {
      this.collection_id = this.state.product.collection.id;
      this.category_id = this.state.product.collection.category.id;
    }
  };

  // modal for adding to cart there is a condition
  // which adds products not already added
  showModal = (e) => {
    if (this.state.cart.length !== 0) {
      if (!cookies.get("Cart")) {
        cookies.set("Cart", [], { path: "/" });
      }
      const cookieData = cookies.get("Cart").map((item) => item);
      const data = [];

      for (let i = 0; i < this.state.cart.length; i++) {
        let k = 0;
        for (let j = 0; j < cookieData.length; j++) {
          if (this.state.cart[i].id === cookieData[j].id) {
            k = 1;
          }
        }
        if (k === 0) {
          data.push(this.state.cart[i]);
          this.props.AddCart(this.state.cart[i]);
        }
      }
      const add_cart = [...data, ...cookieData];
      this._isMounted &&
        this.setState({
          takeMetrik: "",
          takeLength: "",
          message: {},
          cart: [],
        });
      cookies.set(
        "Cart",
        add_cart,
        { path: "/", maxAge: this.expires },
        () => {}
      );
      const checkboxes = document.querySelectorAll("input[type=checkbox]");
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
    }
  };
  // hides cart nodal
  hideModal() {
    this._isMounted && this.setState({ show: false });
    cookies.set("Cart", this.state.cart, { maxAge: this.expires });
  }
  // redicting to cart.js page
  goToCart = () => {
    this.hideModal();
    this.props.history.push("/cart");
  };
  // decrementing quantity of products inside the cart
  decrement(id) {
    let tmp = this.state.cart.find((format) => format.id === id);

    if (tmp.count > 1) {
      tmp.count -= 1;
      const array = [...this.state.cart, tmp];
      const withoutDuplicate = [...new Set(array)];
      this._isMounted &&
        this.setState({
          cart: withoutDuplicate,
        });
    }
  }
  // incrementing quantity of products inside the cart

  increment(id) {
    let tmp = this.state.cart.find((format) => format.id === id);
    if (tmp.units.length > tmp.count) {
      tmp.count += 1;
      const array = [...this.state.cart, tmp];
      const withoutDuplicate = [...new Set(array)];
      this._isMounted &&
        this.setState({
          cart: withoutDuplicate,
        });
    }
  }
  // deleting items form cart
  deleteItem(e, id) {
    e.preventDefault();
    const updatedData = this.state.cart.filter((format) => format.id !== id);
    this._isMounted &&
      this.setState({
        cart: updatedData,
      });
    // calling redux sets length of cart "0"
    this.props.DeleteCart(id);
    // this.sendToParent(updatedData);
  }
  // adding oval and rectangle shapes to the cart
  addCart = (selected_item, e) => {
    if (e.target.checked) {
      const { product } = this.state;
      const itemToAdd = {
        ...selected_item,
        count: 1,
        price: product.price,
        discount: product.discount,
        full_price:
          (product.price - product.discount) *
          ((parseInt(selected_item.size.length) *
            parseInt(selected_item.size.width)) /
            10000),
        image: product.image,
        col_name: product.collection.nameUz,
      };
      const tmp = [...this.state.cart, itemToAdd];
      this._isMounted && this.setState({ cart: tmp });
    } else {
      const tmp = this.state.cart.filter(
        (item) => item.id !== selected_item.id
      );
      this._isMounted && this.setState({ cart: tmp });
    }
  };
  // getting a length of metric carpet in wawrehose
  takeMetrik = (e) => {
    e.preventDefault();
    const id = e.target.value;
    const takeMetrik = this.state.metrik.find(
      (item) => item.id === parseInt(id)
    );
    this._isMounted &&
      this.setState({
        takeMetrik,
        metrikLength: takeMetrik["maxlength"],
        metrikDay: takeMetrik.units[0],
      });
  };
  // get lenght of metric carpet by user and choosing proper message
  takeLength = (e) => {
    e.preventDefault();
    const text = {
      1: {
        nameUz: "Avval o'lchanmni tanlang",
        nameRu: "Сначала выберите размер",
        color: "rgba(198,5,58)",
      },
      2: {
        nameUz: "Miqdorni kiriting",
        nameRu: "Введите количество",
        color: "rgba(198,5,58)",
      },
      3: {
        nameUz: `Tanlanishi mumkin bo'lgan eng ko'p miqdor ${this.state.metrikLength} `,
        nameRu: `доступно на складе ${this.state.metrikLength}`,
        color: "rgba(255,194,0)",
      },
      4: {
        nameUz: "Bu miqdorda mahsulot yo'q",
        nameRu: "Нет товаров в этом количестве",
        color: "rgba(198,5,58)",
      },
    };
    const takeLength = e.target.value;
    const message = {};
    this.number = "";
    if (!takeLength) {
      this.number = 2;
    } else if (takeLength && this.state.takeMetrik.length === 0) {
      this.number = 1;
    } else if (takeLength && this.state.metrikLength >= parseInt(takeLength)) {
      this.number = 3;
    } else if (takeLength && this.state.metrikLength < parseInt(takeLength)) {
      this.number = 4;
    }

    message["nameUz"] = text[this.number]["nameUz"];
    message["nameRu"] = text[this.number]["nameRu"];
    message["color"] = text[this.number]["color"];
    this._isMounted &&
      this.setState({
        takeLength,
        message,
      });
  };
  // adding metric carptes to cart
  toSubmit = async () => {
    if (
      this.state.takeMetrik.size.length > 0 &&
      this.state.takeLength > 0 &&
      this.state.metrikLength >= parseInt(this.state.takeLength)
    ) {
      const itemToAdd = {
        ...this.state.takeMetrik,
        length: this.state.takeLength,
        price: this.state.product.price,
        count: 1,
        full_price:
          (this.state.product.price - this.state.product.discount) *
          ((parseInt(this.state.takeLength) *
            parseInt(this.state.takeMetrik.size.width)) /
            10000),
        discount: this.state.product.discount,
        image: this.state.product.image,
        col_name: this.state.product.collection.nameUz,
      };
      const tmp = [...this.state.cart, itemToAdd];

      this._isMounted && (await this.setState({ cart: tmp }));
      this.showModal();
    }
  };

  // sendToParent = (data) => {
  //   this.props.parentCallback(data);
  // };

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <div className="main">
          <div className="container">
            {/* links for home , category and collection pages related that carpet */}
            <div className="link-div">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="16"
                viewBox="0 0 9 16"
                fill="none"
              >
                <path d="M8.5 15L1.5 8L8.5 1" stroke="#01091C" />
              </svg>
              <p
                onClick={() => this.props.history.push("/")}
                className="text-sm pointer mr-2 ml-2"
              >
                {t("mainPage.text")}
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="16"
                viewBox="0 0 9 16"
                fill="none"
              >
                <path d="M8.5 15L1.5 8L8.5 1" stroke="#01091C" />
              </svg>
              <p
                onClick={() =>
                  this.props.history.push(
                    `/category?category_id=${this.category_id}&nameUz=${this.state.nameUz}&nameRu=${this.state.nameRu}`
                  )
                }
                className="text-sm pointer ml-2 mr-2"
              >
                {this.state.product.collection.category &&
                  this.state.product.collection.category[this.name]}
              </p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="16"
                viewBox="0 0 9 16"
                fill="none"
              >
                <path d="M8.5 15L1.5 8L8.5 1" stroke="#01091C" />
              </svg>
              <p
                onClick={() =>
                  this.props.history.push(
                    `/collection?collection=${this.collection_id}&category_id=${this.category_id}&nameUz=${this.state.nameUz}&nameRu=${this.state.nameRu}`
                  )
                }
                className="text-sm pointer ml-2"
              >
                {this.state.product.collection.nameUz}
              </p>
            </div>
            {/* ending of links */}
            {this.props.collect}
            <div>
              <div className="product-info">
                <div className="upper">
                  {/* section holds gallery and items with checkbox */}
                  <section className="upper-gallery">
                    <div className="gallery">
                      <div className="big-gallery">
                        <RViewerJS>
                          <Swiper
                            centeredSlides={true}
                            slidesPerView={1}
                            autoplay={{
                              delay: 2500,
                              disableOnInteraction: false,
                            }}
                            pagination={{
                              clickable: false,
                            }}
                            grabCursor={true}
                            modules={[Autoplay, Pagination]}
                            className=""
                          >
                            {/* <SwiperSlide>
                            <img
                              src={this.state.product.image}
                              alt={this.state.product.name}
                              className="gallery-image"
                            />
                          </SwiperSlide> */}

                            {this.state.product.photos &&
                              this.state.product.photos.map((item) => (
                                <SwiperSlide key={item.id}>
                                  <img
                                    src={item.image}
                                    alt={item.id}
                                    className="gallery-image"
                                  />
                                </SwiperSlide>
                              ))}
                            {this.state.product.items &&
                              this.state.product.items.map(
                                (photo) =>
                                  photo.image !== null && (
                                    <SwiperSlide key={photo.id}>
                                      <img
                                        src={photo.image}
                                        alt={photo.id}
                                        className="gallery-image"
                                      />
                                    </SwiperSlide>
                                  )
                              )}
                          </Swiper>
                        </RViewerJS>
                      </div>
                      <div className="small-gallery">
                        <div className="second-swiper-container">
                          <RViewerJS>
                            <Swiper
                              counteredSlides={true}
                              direction="vertical"
                              slidesPerView={4}
                              // centeredSlides={true}
                              autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                              }}
                              modules={[Autoplay]}
                              className=" swiper-container"
                              style={{ height: "100%" }}
                            >
                              {/* <SwiperSlide className="swiper-slide">
                                <img
                                  src={this.state.product.image}
                                  alt={this.state.product.name}
                                  className="small-image"
                                />
                              </SwiperSlide> */}

                              {this.state.product.photos &&
                                this.state.product.photos.map((item) => (
                                  <SwiperSlide
                                    key={item.id}
                                    className="swiper-slide"
                                  >
                                    <img
                                      src={item.image}
                                      alt={item.id}
                                      className="small-image"
                                    />
                                  </SwiperSlide>
                                ))}
                              {this.state.product.items &&
                                this.state.product.items.map(
                                  (photo) =>
                                    photo.image !== null && (
                                      <SwiperSlide key={photo.id}>
                                        <img
                                          src={photo.image}
                                          alt={photo.id}
                                          className="small-image"
                                        />
                                      </SwiperSlide>
                                    )
                                )}
                            </Swiper>
                          </RViewerJS>
                        </div>
                      </div>
                    </div>
                    {this.state.product.collection.nameUz && (
                      <div className="options">
                        <h1 className="text-xl">
                          {this.state.product.collection.nameUz +
                            " #" +
                            this.state.art}
                        </h1>

                        <span className="text-sm">
                          {" "}
                          #{this.state.product.name}{" "}
                        </span>
                        {/* <div>
                            {this.state.product.isNew && (
                              <span className="small-span-button new">
                                {t("news.text")}
                              </span>
                            )}
                            {this.state.product.isTrend && (
                              <span className="small-span-button trend">
                                {t("isTrend.text")}
                              </span>
                            )}
                            {this.state.product.discount > 0 && (
                              <span className="small-span-button discount">
                                {t("discount.text")}
                              </span>
                            )}
                          </div> */}

                        <div className="tabs mt-2">
                          {this.state.rectangle.length > 0 && (
                            <>
                              <input
                                type="radio"
                                name="tabGroup2"
                                id="tab1"
                                className="tab"
                                defaultChecked="checked"
                              />
                              <label htmlFor="tab1">
                                {t("rectangle.text")}
                              </label>
                            </>
                          )}
                          {this.state.oval.length > 0 && (
                            <>
                              <input
                                type="radio"
                                name="tabGroup2"
                                id="tab2"
                                className="tab"
                                defaultChecked={
                                  this.state.rectangle.length === 0 && "checked"
                                }
                              />
                              <label htmlFor="tab2">{t("oval.text")}</label>
                            </>
                          )}

                          {this.state.metrik.length > 0 && (
                            <>
                              <input
                                type="radio"
                                name="tabGroup2"
                                id="tab3"
                                className="tab"
                                defaultChecked={
                                  this.state.rectangle.length === 0 &&
                                  this.state.oval.length === 0 &&
                                  "checked"
                                }
                              />
                              <label htmlFor="tab3">{t("metrik.text")}</label>
                            </>
                          )}
                          {this.state.rectangle.length > 0 && (
                            <div className="tab-content">
                              <div className="size-container">
                                <div className="size-header">
                                  <span className="text-sm">
                                    {t("Sizes.text")}
                                  </span>
                                  <span className="text-sm pl-3">
                                    {t("Price.text")}
                                  </span>
                                  <span className="text-sm">
                                    {t("DeliveryDay.text")}
                                  </span>
                                </div>
                                {this.state.rectangle.map((format) => {
                                  return (
                                    <div
                                      className="size-content"
                                      key={format.id}
                                    >
                                      <label
                                        htmlFor={format.id}
                                        className="checkbox-flex checkbox-container"
                                      >
                                        <input
                                          type="checkbox"
                                          id={format.id}
                                          name={format.vId + format.id}
                                          value={format.id}
                                          defaultChecked={this.check}
                                          onClick={(e) =>
                                            this.addCart(format, e)
                                          }
                                        />
                                        <span className="checkmark"></span>
                                        <div className="checktitle text-sm size-content-info">
                                          <span className="d-flex align-items-center">
                                            {format.size.width} X{" "}
                                            {format.size.length}
                                          </span>
                                          <span>
                                            {this.state.product.discount >
                                              0 && (
                                              <p className="text-sm text-through">
                                                <CurrencyFormat
                                                  value={
                                                    (this.state.product
                                                      .collection.price *
                                                      (format.size.width *
                                                        format.size.length)) /
                                                    10000
                                                  }
                                                  displayType={"text"}
                                                  thousandSeparator=" "
                                                  suffix={t("suffix.text")}
                                                />
                                              </p>
                                            )}

                                            <p className="bold">
                                              <CurrencyFormat
                                                value={
                                                  ((this.state.product.price -
                                                    this.state.product
                                                      .discount) *
                                                    (format.size.width *
                                                      format.size.length)) /
                                                  10000
                                                }
                                                displayType={"text"}
                                                thousandSeparator=" "
                                                suffix={t("suffix.text")}
                                              />
                                            </p>
                                          </span>
                                          <span className="d-flex align-items-center">
                                            {format.units[0]}{" "}
                                            {t(
                                              format.units[0] === 1
                                                ? "singleDay.text"
                                                : format.units[0] >= 2 &&
                                                  format.units[0] <= 4
                                                ? "singleDay2.text"
                                                : "singleDay5.text"
                                            )}
                                          </span>
                                        </div>
                                      </label>
                                    </div>
                                  );
                                })}
                              </div>
                              <button
                                onClick={() => {
                                  this.showModal();
                                }}
                                className={
                                  !this.state.cart.some(
                                    (item) => item.size.type === 1
                                  )
                                    ? `disabled-cart-button`
                                    : `addcart-button`
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="23"
                                  height="16"
                                  viewBox="0 0 23 16"
                                  fill="none"
                                >
                                  <path d="M22.4918 5.47114H17.9989L15.1938 0L14.1256 0.642111L16.6045 5.47993H6.39548L8.87435 0.642111L7.80615 0L5.00111 5.47114H0.5V6.79054H2.01668L5.13973 15.3227C5.28651 15.7361 5.65345 16 6.06116 16H16.9388C17.3466 16 17.7135 15.7361 17.8603 15.3227L20.9833 6.79054H22.5V5.47114H22.4918Z" />
                                </svg>
                                <p>{t("addToCart.text")}</p>
                              </button>
                            </div>
                          )}

                          {this.state.oval.length > 0 && (
                            <div className="tab-content">
                              <div className="size-container">
                                <div className="size-header">
                                  <span className="text-sm">
                                    {t("Sizes.text")}
                                  </span>
                                  <span className="text-sm pl-3">
                                    {t("Price.text")}
                                  </span>
                                  <span className="text-sm">
                                    {t("DeliveryDay.text")}
                                  </span>
                                </div>
                                {this.state.oval.map((format) => {
                                  return (
                                    <div
                                      className="size-content"
                                      key={format.id}
                                    >
                                      <label
                                        htmlFor={format.id}
                                        className="checkbox-flex checkbox-container"
                                      >
                                        <input
                                          type="checkbox"
                                          id={format.id}
                                          name={format.vId + format.id}
                                          value={format.id}
                                          defaultChecked={this.check}
                                          onClick={(e) =>
                                            this.addCart(format, e)
                                          }
                                        />
                                        <span className="checkmark"></span>
                                        <div className="checktitle text-sm size-content-info">
                                          <span className="d-flex align-items-center">
                                            {format.size.width} X{" "}
                                            {format.size.length}
                                          </span>
                                          <span>
                                            {this.state.product.discount >
                                              0 && (
                                              <p className="text-sm text-through">
                                                <CurrencyFormat
                                                  value={
                                                    (this.state.product
                                                      .collection.price *
                                                      (format.size.width *
                                                        format.size.length)) /
                                                    10000
                                                  }
                                                  displayType={"text"}
                                                  thousandSeparator=" "
                                                  suffix={t("suffix.text")}
                                                />
                                              </p>
                                            )}

                                            <p className="bold">
                                              <CurrencyFormat
                                                value={
                                                  ((this.state.product.price -
                                                    this.state.product
                                                      .discount) *
                                                    (format.size.width *
                                                      format.size.length)) /
                                                  10000
                                                }
                                                displayType={"text"}
                                                thousandSeparator=" "
                                                suffix={t("suffix.text")}
                                              />
                                            </p>
                                          </span>
                                          <span className="d-flex align-items-center">
                                            {format.units[0]}{" "}
                                            {t("singleDay.text")}
                                          </span>
                                        </div>
                                      </label>
                                    </div>
                                  );
                                })}
                              </div>
                              <button
                                onClick={() => {
                                  this.showModal();
                                }}
                                className={
                                  !this.state.cart.some(
                                    (item) => item.size.type === 0
                                  )
                                    ? `disabled-cart-button`
                                    : `addcart-button`
                                }
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="23"
                                  height="16"
                                  viewBox="0 0 23 16"
                                  fill="none"
                                >
                                  <path d="M22.4918 5.47114H17.9989L15.1938 0L14.1256 0.642111L16.6045 5.47993H6.39548L8.87435 0.642111L7.80615 0L5.00111 5.47114H0.5V6.79054H2.01668L5.13973 15.3227C5.28651 15.7361 5.65345 16 6.06116 16H16.9388C17.3466 16 17.7135 15.7361 17.8603 15.3227L20.9833 6.79054H22.5V5.47114H22.4918Z" />
                                </svg>
                                <p>{t("addToCart.text")}</p>
                              </button>
                            </div>
                          )}
                          {this.state.metrik.length > 0 && (
                            <div className="tab-content">
                              {this.lang === "ru" ? (
                                <div className="size-container">
                                  Наша метражная продукция доступна для заказа
                                  только в наших магазинах. Вы можете найти
                                  ближайший магазин в разделе{" "}
                                  <a
                                    style={{ color: "blue" }}
                                    href="https://sag.uz/contacts"
                                    target="_blank"
                                  >
                                    Магазины
                                  </a>{" "}
                                  на нашем сайте или позвонив по короткому
                                  номеру{" "}
                                  <strong>
                                    {" "}
                                    <a href={"tel:" + 1365}>1365</a>
                                  </strong>{" "}
                                  .
                                </div>
                              ) : (
                                <div className="size-container">
                                  Kesib sotiladigan metrli mahsulotlarimizni
                                  faqatgina do'konlarimizdan buyurtma
                                  qilishingiz mumkin. Eng yaqin do'konni
                                  saytimizning{" "}
                                  <a
                                    style={{ color: "blue" }}
                                    href="https://sag.uz/contacts"
                                    target="_blank"
                                  >
                                    Do'konlar
                                  </a>{" "}
                                  qismidan topishingiz yoki{" "}
                                  <strong>
                                    {" "}
                                    <a href={"tel:" + 1365}>1365</a>
                                  </strong>{" "}
                                  qisqa raqamiga telefon qilib aniqlashingiz
                                  mumkin.
                                </div>
                              )}

                              {/* <div className="size-container">
                               
                                <div className="flex flex-column">
                                  <label
                                    className="text-sm"
                                    htmlFor="width"
                                  >
                                    {t("width.text")} ({t("cm.text")})
                                  </label>
                                  {Object.keys(this.state.takeMetrik).length >
                                    0 && (
                                    <p>
                                      {t("DeliveryDay.text")}{" "}
                                      {this.state.metrikDay}{" "}
                                      {t("singleDay.text")}
                                    </p>
                                  )}

                                  <select
                                    className="base-input pointer"
                                    name="width"
                                    id="cars"
                                    onChange={(e) => this.takeMetrik(e)}
                                    defaultValue={t("chose.text")}
                                  >
                                    <option disabled value={t("chose.text")}>
                                      {t("chose.text")}
                                    </option>
                                    {this.state.metrik.map((format) => (
                                      <option
                                        key={format.id}
                                        className="size-content"
                                        value={format.id}
                                      >
                                        {format.size.width}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="flex flex-column mt-2">
                                  <label
                                    htmlFor="length"
                                    className="text-sm"
                                  >
                                    {" "}
                                    {t("length.text")} ({t("cm.text")})
                                  </label>
                                  <input
                                    className="base-input"
                                    onChange={(e) => this.takeLength(e)}
                                    value={this.state.takeLength}
                                    id="length"
                                    type="number"
                                  />
                                </div>
                                {Object.keys(this.state.message).length > 0 && (
                                  <div>
                                    <section
                                    
                                      style={{
                                        color: `${this.state.message["color"]}`,
                                      }}
                                    >
                                      {this.state.message[this.name]}
                                    </section>

                                    <section className="d-flex gap-4">
                                      {t("priceFor.text")} :{" "}
                                      {this.state.product.discount > 0 && (
                                        <p className="text-sm text-through ">
                                          <CurrencyFormat
                                        value={
                                          this.state.product.collection.price
                                        }
                                        displayType={"text"}
                                        thousandSeparator=" "
                                        suffix={t("suffix.text")}
                                      />{" "}
                                        </p>
                                      )}
                                      <CurrencyFormat
                                        value={
                                          this.state.product.price - this.state.product.discount
                                        }
                                        displayType={"text"}
                                        thousandSeparator=" "
                                        suffix={t("suffix.text")}
                                      />{" "}
                                    </section>
                                    {Object.keys(this.state.takeMetrik)
                                      .length && (
                                      <section className="d-flex gap-4">
                                        (({this.state.takeMetrik.size["width"]}
                                          /100) * ({this.state.takeLength}/100)) *{" "}
                               
                                          <CurrencyFormat
                                        value={
                                          this.state.product.price - this.state.product.discount
                                        }
                                        displayType={"text"}
                                        thousandSeparator=" "
                                        suffix={t("suffix.text")}
                                      />
                                   
                                          = 
                                        <CurrencyFormat
                                          value={
                                            ((this.state.product.price - this.state.product.discount) *
                                              (this.state.takeMetrik.size[
                                                "width"
                                              ] *
                                                this.state.takeLength)) /
                                            10000
                                          }
                                          displayType={"text"}
                                          thousandSeparator=" "
                                          suffix={t("suffix.text")}
                                        />
                                        
                                        
                                      </section>
                                    )}
                                  </div>
                                )}
                              </div>
                              <button
                               onClick={() => {
                                this.toSubmit();
                              }}
                               className={ this.state.takeLength > 0 &&
                                this.state.takeMetrik &&
                                this.state.metrikLength >=
                                  parseInt(this.state.takeLength) ? `addcart-button`: ` disabled-cart-button`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="16" viewBox="0 0 23 16" fill="none" >
                                  <path d="M22.4918 5.47114H17.9989L15.1938 0L14.1256 0.642111L16.6045 5.47993H6.39548L8.87435 0.642111L7.80615 0L5.00111 5.47114H0.5V6.79054H2.01668L5.13973 15.3227C5.28651 15.7361 5.65345 16 6.06116 16H16.9388C17.3466 16 17.7135 15.7361 17.8603 15.3227L20.9833 6.79054H22.5V5.47114H22.4918Z" />
                                </svg>
                                <p>
                                  {t("addToCart.text")}
                                </p>
                              </button> */}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </section>
                  {/* additional informatinos about carpet */}
                  <section className="parent-content">
                    <div className="tabs mt-2">
                      <input
                        type="radio"
                        name="tabGroup1"
                        id="tab22"
                        className="property-tab"
                      />
                      <label className="radio-label" htmlFor="tab22">
                        {t("parametrs.text")}
                      </label>
                      <input
                        type="radio"
                        name="tabGroup1"
                        id="tab23"
                        className="property-tab"
                      />
                      <label className="radio-label" htmlFor="tab23">
                        {t("Information.text")}
                      </label>
                      {/* <div className="tab-content">
                        <div className="size-container">
                          <div className="grid-container">
                     
                            {this.state.product.collection.parameters &&
                              this.state.product.collection.parameters.map(
                                (parameter) => {
                                  return (
                                    <div
                                      key={parameter.id}
                                      className=" grid-item"
                                    >
                                  
                                      <div className="grid-image">
                                        <img
                                          src={parameter.image}
                                          alt={parameter.nameUz}
                                        />
                                      </div>
                                      <p>{parameter[this.name]}</p>
                                
                                    </div>
                                  );
                                }
                              )}
                    
                          </div>
                        </div>
                      </div> */}
                      <div className="tab-content">
                        <div className="main-params">
                          <div className="single-param">
                            <p> {t("density.text")}: </p>
                            <h3 className="text-sm">
                              {this.state.product.collection[this.density]}
                            </h3>
                          </div>
                          <div className="single-param">
                            {" "}
                            <p>{t("base.text")}: </p>
                            <h3 className="text-sm">
                              {this.state.product.collection[this.basis]}
                            </h3>
                          </div>
                          <div className="single-param">
                            <p>{t("thickness.text")}: </p>
                            <h3 className="text-sm">
                              {this.state.product.collection[this.pileHeight]}
                            </h3>
                          </div>
                          {/* <div className="single-param">
                            <p>{t("yarn.text")}: </p>
                            <h3 className="text-sm">
                              {this.state.product.collection[this.pileThread]}
                            </h3>
                          </div> */}
                          <div className="single-param">
                            <p>{t("yarnIng.text")}: </p>
                            <h3 className="text-sm">
                              {
                                this.state.product.collection[
                                  this.threadComposition
                                ]
                              }
                            </h3>
                          </div>
                          <div className="single-param">
                            <p>{t("weight.text")}: </p>
                            <h3 className="text-sm">
                              {this.state.product.collection[this.weight]}
                            </h3>
                          </div>
                          {/* <div className="single-param">
                            <p>{t("bound.text")}: </p>
                            <h3 className="text-sm">
                              {
                                this.state.product.collection[
                                  this.edgeProcessing
                                ]
                              }
                            </h3>
                          </div>
                          <div className="single-param">
                            <p>{t("design.text")}: </p>

                            <h3 className="text-sm">
                              {this.state.product.collection[this.design]}
                            </h3>
                          </div> */}
                        </div>
                        <div className="additional-params"></div>
                      </div>
                      <div className="tab-content">
                        {this.state.product.collection.descriptionUz &&
                          ReactHtmlParser(
                            this.state.product.collection[this.description]
                          )}
                      </div>
                    </div>
                  </section>
                </div>
                <div
                  style={{
                    fontSize: "22px",
                    fontWeight: "500",
                    lineHeight: "normal",
                    paddingBottom: "6px",
                  }}
                >
                  {t("similarProducts.text")}
                </div>
                <Swiper
                  slidesPerView={1}
                  spaceBetween={20}
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay]}
                  breakpoints={{
                    576: { slidesPerView: 2 },
                    890: { slidesPerView: 2 },
                    992: { slidesPerView: 3 },
                    1200: { slidesPerView: 4 },
                  }}
                  className="mySwiper"
                  style={{ paddingTop: "10px", paddingBottom: "10px" }}
                >
                  <div className="trend-products">
                    {this.state.same_products.map((cat) => (
                      <SwiperSlide
                        key={cat.id}
                        className="d-flex flex-nowrap justify-content-center"
                      >
                        <Link to={`/product?product_id=${cat.id}`} key={cat.id}>
                          <ProductCard
                            image={
                              cat.image
                                ? BaseUrl.slice(0, -7) + cat.image
                                : BaseUrl.slice(0, -7) + cat.collection.image
                            }
                            articul={"#" + cat.name}
                            price={cat.price}
                            name={
                              cat.collection.nameUz + " " + cat.name.slice(2, 6)
                            }
                            isTrend={cat.isTrend}
                            isNew={cat.isNew}
                            isDiscount={cat.discount}
                          />
                        </Link>
                      </SwiperSlide>
                    ))}
                  </div>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
        {/* modal for adding carpet to cart */}
        <div id="myModal" className={this.state.show ? `mod modalOpen` : "mod"}>
          <div className="modalContent">
            <h1 className="text-lg">{t("addedCart.text")}</h1>
            <div className="cart-scrol">
              {this.state.cart.map((car) => (
                <div className="added-items mt-2" key={car.id}>
                  <div className="added-item-info">
                    <img src={car.image} alt={car.name} />
                    <div className="infos">
                      <h4 className="text-xs">{car.col_name}</h4>
                      <h2 className="bold text">{car.vId}</h2>
                      {(car.size.type === 1 || car.size.type === 0) && (
                        <h5 className="text text-sm">
                          {car.size.width}X{car.size.length}
                        </h5>
                      )}
                      {(car.size.type === 2 || car.size.type === 5) && (
                        <h5 className="text text-sm">
                          {car.size.width}X{car.length}
                        </h5>
                      )}
                    </div>
                  </div>
                  <div className="modal-elements">
                    <div>
                      <div className=" flex center">
                        <span className="text-sm bold">
                          {t("DeliveryPeriod.text")}:
                        </span>
                        <span>
                          {" "}
                          {car.units[car.count - 1]} {t("singleDay.text")}
                        </span>
                      </div>
                      <div className="price-config">
                        {(car.size.type === 1 || car.size.type === 0) && (
                          <div className="less-more">
                            <button
                              className="item-button gray"
                              onClick={() => this.decrement(car.id)}
                            >
                              <i className="fa-solid fa-minus"></i>
                            </button>
                            <span>{car.count}</span>
                            <button
                              className="item-button gray"
                              onClick={() => this.increment(car.id)}
                            >
                              <i className="fa-solid fa-plus"></i>
                            </button>
                          </div>
                        )}

                        <div className="text bold ml-1">
                          <CurrencyFormat
                            value={parseInt(car.full_price * car.count)}
                            displayType={"text"}
                            thousandSeparator=" "
                            suffix={t("suffix.text")}
                          />
                        </div>
                      </div>
                    </div>
                    <button
                      className="item-button gray"
                      onClick={(e) => this.deleteItem(e, car.id)}
                    >
                      <i className="fa-solid fa-xmark"></i>
                    </button>
                  </div>
                </div>
              ))}
            </div>
            {/* buttons for redirecting to cart or keeps on page */}
            <div className="modal-buttons pt-2">
              <PrimaryButton
                onClick={this.goToCart}
                value={t("goToCart.text")}
              />
              <SecondaryButton
                onClick={this.hideModal}
                value={t("goToProduct.text")}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}
// redux functions called adding and deleting items from cart
function mapDispatchToProps(dispatch) {
  return {
    actFetchProductsRequest: () => dispatch(actFetchProductsRequest()),
    AddCart: (item) => dispatch(AddCart(item)),
    DeleteCart: (id) => dispatch(DeleteCart(id)),
  };
}
export default withRouter(
  connect(null, mapDispatchToProps)(withTranslation()(Product))
);
